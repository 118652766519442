<template>
  <section>
    <title-section
      title="Mi Perfil"
    >
      <div class="level-item">
        <button class="button button_micro_dark" @click="activeModalEditProfile = true">
          EDITAR PERFIL
        </button>
      </div>
    </title-section>
    <my-profile-main
      :activeModalEditProfile="activeModalEditProfile"
      @close-modal="activeModalEditProfile = false"
    />
  </section>
</template>

<script>
export default {
  name: 'MyProfile',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MyProfileMain: () => import('@/components/MyProfile/MyProfileMain.vue')
  },
  data () {
    return {
      activeModalEditProfile: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
  }
</style>
